var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SlideXLeftTransition',{attrs:{"duration":200}},[_c('section',{staticClass:"sub-menu change-mobile"},[_c('span',{staticClass:"btn-dash-sm desktop",on:{"click":function($event){return _vm.$emit('show')}}},[_c('i',{staticClass:"fas fa-times"})]),_c('span',{staticClass:"i-btn i-btn-sm d-flex align-items-center negrita my-0 btn-position mobile",on:{"click":function($event){return _vm.$emit('show')}}},[_vm._v(_vm._s(`<-`))]),[_c('div',{staticClass:"row px-4 mt-2"},[_c('h3',[_vm._v(_vm._s(_vm.item.name != 'Cuenta' ? _vm.item.name : _vm.user.name))])]),_c('p',{staticClass:"text_xs normal ml-4 mb-2"},[_vm._v(" "+_vm._s(_vm.selected_bot && _vm.selected_bot.bot_name)+" ")]),_c('div',{staticClass:"item_box mb-6"},_vm._l((_vm.info_item),function(info){return _c('div',{key:info.id},[(!info.sub_category)?[(
                info.path &&
                  (info.hasOwnProperty('category') ? info.category : true)
              )?_c('span',{on:{"click":function($event){return _vm.$emit('show')}}},[(info.category)?_c('router-link',{staticClass:"item-link",attrs:{"to":info.path}},[_vm._v(_vm._s(info.name))]):_c('router-link',{class:{'item-link': info.name != 'Previsualizar', 'btn btn-base sm ml-3': info.name == 'Previsualizar'},attrs:{"to":info.path}},[_vm._v(_vm._s(info.name))])],1):(info.action)?[_c('hr',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.name == 'Cuenta'),expression:"item.name == 'Cuenta'"}],staticClass:"my-3 mx-3"}),_c('span',{staticClass:"item-link click",on:{"click":function($event){$event.preventDefault();return info.action.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.active_view == 'assigned_bots' ? info.name == 'Eliminar' ? 'Desvincular' : info.name : info.name))])]:_c('span',{staticClass:"item-link text-disabled"},[_vm._v(_vm._s(info.name))])]:[_c('span',{class:{
                'item-link arrow': true,
                'arrow-2': info.category == true,
                'item-link-active': info.category == true,
              },on:{"click":function($event){return _vm.showDropCategory(info)}}},[_vm._v(_vm._s(info.name))]),_c('SlideYUpTransition',{attrs:{"duration":200}},[_c('SubMenuLvl2',{directives:[{name:"show",rawName:"v-show",value:(info.category),expression:"info.category"}],attrs:{"info":info}})],1)]],2)}),0)]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }