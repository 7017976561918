<template>
  <section class="validate_agent-access" />
</template>

<script>
import { mapState } from 'vuex';

export default {

  created() {
    this.restrictedAccess();
  },

  computed: {
    ...mapState(['active_view', 'selected_bot', 'isActiveViewSetted']),
  },

  watch: {
    selected_bot(val) {
      this.restrictedAccess();
    }
  },

  methods: {
    restrictedAccess() {
      if (this.active_view == 'assigned_bots') {
        if (
          this.$route.path == '/integraciones' ||
          this.$route.path == '/facturacion' ||
          this.$route.path == '/dashboard/planes' ||
          this.$route.path == '/agentes'
        ) {
          this.$router.push('/dashboard');

          this.$swal({
            title: "Los sentimos, no tienes acceso a esta parte del sistema.",
            icon: "info",
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.validate_agent-access {
  display: none;
}
</style>
