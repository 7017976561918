<template>
  <section class="validate-descative-bot" />
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['selected_bot']),
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getValidation(val);
      }
    }
  },

  methods: {
    getValidation(bot) {
      if (bot.activated == false) {
        if (this.$route.query.seccion !== 'new') {
          this.$swal({
            icon: "info",
            title: "Activa tu chatbot con un plan.",
            text: "Para seguir ocupando este chatbot debes asignar un plan pagado.",
            confirmButtonText: "Planes",
          });
          this.$router.push({
            name: "dashboard-planes",
            query: { seccion: 'activar',
            bot_id: bot.id }
          });
        } else if (this.$route.query.from == 'nuevo-chatbot') {
            this.$router.push({
              name: "dashboard-planes",
              query: { seccion: 'mejorar-plan',
              bot_id: bot.id }
            });
          }
      } else {
        if (this.$route.query.from == 'nuevo-chatbot') {
          this.$router.push({
            name: "dashboard-planes",
            query: { seccion: 'mejorar-plan',
            bot_id: bot.id }
          });
        }
        if (this.$route.query.seccion == 'activar') {
          this.$router.push({
            name: "dashboard-planes",
            query: { seccion: 'mejorar-plan',
            bot_id: bot.id }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.validate-descative-bot {
  display: hidden;
}
</style>
