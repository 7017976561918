import Vue from "vue";
import { io } from "socket.io-client";
import dashboard_api from "@/dashboard_api.js";
import environment from "@/environment.js";
import store from "@/store/store.js";
import {
  botSiccId,
  getIndustriesFromLocalStorage,
  getCompaniesFromLocalStorage,
  capitalizeFirstLetter,
} from "@/utils/utils";
import filtersDatabotLive from "@/utils/filtersDatabotLive.js";

const socket = io(environment.socket_url);

socket.on("CONNECTED", async (data) => {
  console.log("CONECTADO!");
  const roomToken = store.state.roomToken;
  const response = await dashboard_api.post("/bot/list_bot_ids_by_user", {
    user_id: store.state.user.id,
  });
  const botIds = response.data;
  for (const botId of botIds) {
    socket.emit("AUTO_RECONNECT", {
      botId,
    });
  }
  // reconectando mediante room
  if (roomToken) {
    socket.emit("AUTO_RECONNECT", {
      roomToken,
    });
  }
});

socket.on("SOUND_NOTIFICATION_NEW_ACTIVE_CHAT_MESSAGE", async (data) => {
  const userId = store.state.user.id;
  const { botId } = data;
  const { from } = data;
  const activeChats = store.state.activeChatRooms;
  let activate = false;
  let activateSound = await activateSoundNotification(
    botId,
    userId,
    data.leadCustomData,
    "SOUND_NOTIFICATION_NEW_ACTIVE_CHAT_MESSAGE"
  );
  if (from === "Cliente" && activateSound) {
    if (activeChats.length > 0) {
      if (activeChats.findIndex((activeChat) => activeChat.room_token == data.room) > -1) {
        activate = true;
      }
    } else {
      const chat_rooms = await dashboard_api.get(
        `/chat_rooms_by_user/${userId}/${store.state.active_view || 0}/all`
      );
      const activeChatsDB = chat_rooms.data.filter((chat) => chat.agent_id == userId);
      store.commit("setActiveChatRooms", activeChatsDB);
      if (activeChatsDB.findIndex((activeChat) => activeChat.room_token == data.room) > -1) {
        activate = true;
      }
    }
    if (activate) Vue.prototype.$playSoundNotification("n_databotlive", userId);
  }
});

// notificacion de sonido para nueva conversacion
socket.on("PUSH_DATA", async ({ id, botId, reconnect }) => {
  if (!reconnect) {
    // no debe sonar si se trata de una reconeccion actualizacion pagina
    const userId = store.state.user.id;
    let activateSound = await activateSoundNotification(botId, userId, {}, "PUSH_DATA");
    if (id && activateSound) {
      Vue.prototype.$playSoundNotification("n_conversation", userId);
    }
  }
});

// notificacion de sonido para nuevo lead
socket.on("SOUND_NOTIFICATION_NEW_LEAD", async ({ botId, leadCustomData }) => {
  const userId = store.state.user.id;
  let activateSound = await activateSoundNotification(
    botId,
    userId,
    leadCustomData,
    "SOUND_NOTIFICATION_NEW_LEAD"
  );
  if (activateSound) {
    Vue.prototype.$playSoundNotification("n_leads", userId);
  }
});

// notificacion de sonido para nueva conversacion pendiente
socket.on("SOUND_NEW_PENDING_CONVERSATION", async ({ botId, leadCustomData }) => {
  const userId = store.state.user.id;
  let activateSound = await activateSoundNotification(
    botId,
    userId,
    leadCustomData,
    "SOUND_NEW_PENDING_CONVERSATION"
  );
  if (activateSound) {
    Vue.prototype.$playSoundNotification("n_pending_conversation", userId);
  }
});

function activateSoundNotification(botId, userId, leadCustomData = {}, event) {
  return new Promise(async (resolve, reject) => {
    const isUserLogged = store.state.user.id; // solo se debe hacer esto si usuario logeado
    if (isUserLogged) {
      botId = typeof botId === "string" ? parseInt(botId) : botId; // validacion int
      const owner = store.state.active_view === "my_bots";
      let activate = false;
      let { bots } = store.state;
      if (bots.length > 0) {
        if (bots.findIndex((bot) => bot.id == botId) > -1) {
          activate = true;
        }
      } else {
        const response = await dashboard_api.get(`/bot/list/${userId}?owner=${owner}`);
        bots = response.data;
        // se agregan los bots al store
        store.commit("setActiveBots", bots);
        // se verifica que el botId de la nueva conversación con el bot perteneza a los botsDB del usuario
        if (bots.findIndex((bot) => bot.id == botId) > -1) {
          activate = true;
        }
      }
      // alguna condicion extra para decidir si emitir sonido o no
      // condicion SICC
      if (bots.findIndex((bot) => bot.id == botId) > -1 && botId == botSiccId()) {
        activate = siccCardBelongsFilter(leadCustomData);
      }
      // condicion filtros personalizados
      if (
        bots.findIndex((bot) => bot.id == botId) > -1 &&
        filtersDatabotLive.hasCustomFilters(botId)
      ) {
        activate = cardBelongsFilter(leadCustomData);
      }
      resolve(activate);
    } else {
      resolve(false);
    }
  });
}

function siccCardBelongsFilter(leadCustomData) {
  let activate = true;
  const { filter_nombre_industria, filter_nombre_empresa } = leadCustomData;
  // si es que no hay ningun filtro, sonar directamente
  if (getIndustriesFromLocalStorage().length > 0 || getCompaniesFromLocalStorage().length > 0) {
    if (
      getIndustriesFromLocalStorage().includes(filter_nombre_industria) &&
      getCompaniesFromLocalStorage().length == 0
    ) {
      activate = true;
    } else if (
      getIndustriesFromLocalStorage().includes(filter_nombre_industria) &&
      getCompaniesFromLocalStorage().includes(filter_nombre_empresa)
    ) {
      activate = true;
    } else if (
      getIndustriesFromLocalStorage().length == 0 &&
      getCompaniesFromLocalStorage().includes(filter_nombre_empresa)
    ) {
      activate = true;
    } else {
      activate = false;
    }
  }

  return activate;
}

function cardBelongsFilter(leadCustomData) {
  let activateSound = false;
  let selectedFilters = localStorage.getItem("customFilters")
    ? JSON.parse(localStorage.getItem("customFilters"))
    : {};

  let fields = Object.keys(selectedFilters);
  // convirtiendo nulls a undefined (normalizando para filtrar correctamente "otros")
  fields.forEach((field) => {
    selectedFilters[field] = selectedFilters[field].map((value) =>
      value === null ? undefined : value
    );
  });
  let hasFieldValues = fields.some((field) => selectedFilters[field].length > 0);
  if (fields.length > 0 && hasFieldValues) {
    let satisfy = false;
    let satisfies = []; // todos deben ser true
    for (const field of fields) {
      if (selectedFilters[field].includes(leadCustomData[field])) {
        satisfies.push(true);
      } else if (selectedFilters[field].length > 0) {
        satisfies.push(false);
      }
    }
    if (satisfies.every((el) => el) && satisfies.length > 0) {
      satisfy = true;
    } else {
      satisfy = false;
    }
    satisfies = [];
    activateSound = satisfy;
  } else {
    activateSound = true;
  }
  return activateSound;
}

export default socket;
