import axios from 'axios';
import environment from '@/environment.js';

export default {
  list(assistant_id) {
    return axios.get(`${environment.RASA_URL}/api/assistants/${assistant_id}`);
  },
  listOne(assistant_id) {
    return axios.get(
      `${environment.RASA_URL}/api/assistants/${assistant_id}?export=true`,
    );
  },
  update(assistant_id, payload) {
    return axios.put(
      `${environment.RASA_URL}/api/assistants/${assistant_id}`,
      payload,
    );
  },
  create(assistant_id, payload) {
    return axios.post(
      `${environment.RASA_URL}/api/assistants/${assistant_id}`,
      payload,
    );
  },
  delete(assistant_id) {
    return axios.delete(
      `${environment.RASA_URL}/api/assistants/${assistant_id}`,
    );
  },
  trainLlm(assistant_id) {
    return axios.post(
      `${environment.RASA_URL}/api/assistants/${assistant_id}/train_llm`,
      {
        modules_to_train: ['knowledge'],
      },
    );
  },
  generateFromWatson(assistant_id) {
    return axios.post(
      `${environment.RASA_URL}/api/assistants/${assistant_id}/generate_from_watson`,
      {
        train_after_sync: true,
      },
    );
  },
};
