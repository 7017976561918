<template>
  <FadeTransition :duration="200">
    <SpinnerLoading v-if="!dataReady" />
    <section v-else class="layout">
      <ValidateAgentAccess v-if="selected_bot" />
      <div class="item-1">
        <SideBar @activeViewSetted="activeViewSetted" />
      </div>
      <div class="item-2">
        <DaysLeft v-if="selected_bot" />
        <ButtonBots
          @changeLoading="changeLoading"
          @letShowData="letShowData"
          :activeViewSetted="showActiveViewRolBot"
        />
      </div>
      <SpinnerLoading v-if="loading" />
      <div v-else :class="{ 'item-3': true, 'pt-5': spaceTop }">
        <router-view
          :key="selected_bot.id"
          v-if="selected_bot && selected_bot.id"
        />
      </div>
    </section>
  </FadeTransition>
</template>

<script>
import ButtonBots from '@/components/ButtonBots';
import { FadeTransition } from 'vue2-transitions';
import { mapMutations, mapState } from 'vuex';
import SideBar from '@/components/SidebarPlugin/SideBar.vue';
import DaysLeft from '@/components/DaysLeft';
import dashboard_api from '@/dashboard_api';
import dashboard_api_v2 from '@/dashboard_api_v2';
import {
  addCustomScript,
  hasActiveSoundNotifications,
  waitForAudioRunning,
  insertSoporteBotDatabot,
} from '@/utils/utils';
import socket from '@/plugins/sockets';
import ValidateAgentAccess from '@/components/ValidateAgentAccess';

export default {
  components: {
    ButtonBots,
    FadeTransition,
    DaysLeft,
    SideBar,
    ValidateAgentAccess,
  },

  data() {
    return {
      showActiveViewRolBot: false,
      dataReady: false,
      isSoundRunning: false,
      loading: false,
    };
  },

  created() {
    this.oneSignalPlayerId();
  },

  mounted() {
    if (this.$route.query.seccion) {
      this.scrollTo(2000);
    }
    socket.on('PUSH_DATA', data => {
      console.log('PUSH_DATA layout', data);

      // Validacion: notificacion llegará cuando la conversacion sea con el bot seleccionado
      if (data.botId == this.selected_bot.id) {
        // Valida que no llegue la notificacion si ya fue calificada
        if (data.payload.room_token != this.roomToken && !data.reconnect) {
          // Valida que llegue notificacion al owner y/o agente segun categoria de conversación
          if (
            !this.agentAccount ||
            !this.agentAccount.category ||
            (this.agentAccount.category &&
              this.agentAccount.category.includes(data.payload.category))
          ) {
            this.$swal({
              toast: true,
              html: `<span><span class="notification_number mr-2">1</span> Nueva conversación</span>`,
              position: 'top-end',
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: false,
            });
            // console.log("new notification message");
            this.setNewMessage(true);
          }
        }
        if (typeof data.botId === 'string') data.botId = parseInt(data.botId);

        // if (!data.reconnect) {
        if (data.id && this.user.botIds.includes(data.botId)) {
          let dlive_id = dlive_id != 0 ? dlive_id : data.id;
          // Guardando evento en store param 'push_data' para usar watch en chat_cards y agregar la tarjeta
          this.setPushData(data);
        }
        // }
      }
    });

    const self = this;
    // esta parte de codigo corrige bug notificaciones de sonido no suenan fuera de databot
    if (hasActiveSoundNotifications() && self.isSoundRunning == false) {
      waitForAudioRunning();
      self.isSoundRunning = true;
    }
    // este if actualiza la ventana del bot cuando se entra a dashboard
    if (self.$route.path == '/dashboard') {
      window.onload = function () {
        insertSoporteBotDatabot();
      };
    }
    this.getBotIds();
  },

  computed: {
    ...mapState([
      'user',
      'active_view',
      'selected_bot',
      'skill_template',
      'ownerBots',
      'registerData',
      'getSocketLeads',
      'selectedChat',
      'roomToken',
      'agentAccount',
      'templates',
    ]),
    spaceTop() {
      return (
        this.$route.path != '/databot-live' &&
        this.$route.path != '/preview' &&
        this.$route.path != '/clients'
      );
    },
  },
  watch: {
    skill_template: {
      immediate: true,
      handler(val) {
        this.getTemplate();
      },
    },
    selected_bot() {
      this.setShowClientInfo(false);
      // code for bots with skill base
      if (this.selected_bot && this.selected_bot.has_skill_base) {
        this.getSkillTemplate(this.selected_bot);
        this.getTemplate();
      }
    },
    $route: {
      immediate: true,
      handler(to, from) {
        this.scrollTo(2000);
        this.hiddeBotView(to);
        if (to.path != '/databot-live') {
          // Reset dlive stores vals
          this.setCurrentRoomToken(null);
          this.setSelectedChat(null);
          this.setShowClientInfo(false);
        }
      },
    },
  },
  methods: {
    ...mapMutations([
      'logout',
      'setRegisterProcess',
      'setNewMessage',
      'setPushData',
      'setShowClientInfo',
      'setSocketLeads',
      'setSelectedChat',
      'setRouterSkillBase',
      'setCurrentRoomToken',
      'setTemplateValue',
    ]),

    hiddeBotView(route) {
      let bot_div = document.getElementById('chat_box_identifier');

      if (bot_div) {
        if (route.name == "databot-live") bot_div.classList.add("not_here");
        else if (route.name == "clients-show") bot_div.classList.add("not_here");
        else bot_div.classList.remove("not_here");
      }
    },
    getBotIds() {
      // se colocan los ids de los bots en localstorage
      // se ejecuta siempre (1 vez) para gestionar los casos de agregar/eliminar bots de agentes en localstorage
      dashboard_api
        .post('/bot/list_bot_ids_by_user', {
          user_id: this.$store.state.user.id,
        })
        .then(async botsResponse => {
          let botIds = botsResponse.data;
          for (const botId of botIds) {
            socket.emit('AUTO_RECONNECT', {
              botId,
            });
          }
          this.$store.commit('setBotIdsToLocalStorage', botIds);
          // colocando en store los botIds y sus business_types
          dashboard_api_v2
            .get(`bots/get_bot_info/${botIds}`)
            .then(async responses => {
              this.$store.commit('setBotsWithBusinessTypeShow', responses.data);
            })
            .catch(error => console.log('error:'));

          this.dataReady = true;
        });
    },

    changeLoading(val) {
      // Este método es para activar el loading al cambiar la vista de los bots propios por los de agente cuando ya cuentan con muchos datos
      this.loading = val;
    },

    letShowData(val) {
      // si no hay bot seleccionado
      if (val == false) {
        this.validateCreatedBots(this.active_view);
      }
    },

    validateCreatedBots(view) {
      // validacion que actua cuando el usuario crea su cuenta pero sale del flujo de creación de bot, bot == null
      if (view != 'my_bots' && this.ownerBots.length == 0) {
        this.$swal({
          icon: 'info',
          title: 'No tienes chatbots registrados.',
          text: 'Puedes crear un chatbot o volver a ingresar.',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Crear',
          cancelButtonText: 'Salir',
        }).then(resp => {
          if (resp.isConfirmed) {
            let my_register = this.registerData;
            my_register.step = 2;
            this.setRegisterProcess(my_register);
            this.$router.push({
              name: 'register',
              query: { name: 'primer-chatbot' },
            });
          } else {
            this.logout();
            this.$router.push('/login');
          }
        });
      }
    },

    activeViewSetted(value) {
      this.showActiveViewRolBot = value;
    },
    oneSignalPlayerId() {
      const self = this;
      let push_subscriber;
      OneSignal.push(() => {
        OneSignal.isPushNotificationsEnabled(isEnabled => {
          dashboard_api
            .get(`/push_subscribers/by_user_id/${self.user.id}`)
            .then(response => {
              push_subscriber = response.data;

              if (isEnabled) {
                // console.log("Push notifications are enabled!");
                OneSignal.getUserId(userId => {
                  if (push_subscriber) {
                    if (push_subscriber.sid != userId) {
                      dashboard_api.put(
                        `/push_subscribers/${push_subscriber.id}`,
                        {
                          push_subscriber: {
                            sid: userId,
                            active: true,
                          },
                        },
                      );
                    } else {
                      dashboard_api.put(
                        `/push_subscribers/${push_subscriber.id}`,
                        {
                          push_subscriber: {
                            active: true,
                          },
                        },
                      );
                    }
                  } else {
                    dashboard_api
                      .post('/push_subscribers', {
                        push_subscriber: {
                          user_id: self.user.id,
                          sid: userId,
                          active: true,
                        },
                      })
                      .then(result => console.log(result))
                      .catch(error => console.log('error:'));
                  }
                  // Make a POST call to your server with the user ID
                  // Mixpanel Example
                  // mixpanel.people.set({ $onesignal_user_id: userId });
                });
              } else {
                console.log('Push notifications are not enabled yet.');

                dashboard_api
                  .put(`/push_subscribers/${push_subscriber.id}`, {
                    push_subscriber: {
                      active: false,
                    },
                  })
                  .then(result => console.log(result))
                  .catch(error => console.log('error:'));
              }
            })
            .catch(error => console.log('error:'));
        });
      });
    },

    // JUST FOR BOTS WITH SKILL BASE
    // TODO By default, TEST001, change this later
    async getTemplate() {
      // console.log("---->", this.skill_template);
      let data = (
        await this.$store.dispatch(
          'dtSectionsModule/listByTemplateName',
          this.skill_template.value,
        )
      ).sectionsFront;
      if (data) {
        let skill_base = data.map(el => ({
          name: el.section_ui_name,
          path: `/chatbot-center/skill-base/${el.section_ui_name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .split(' ')
            .join('-')}`,
          original_name: el.section_name,
          bot_id: this.selected_bot.id,
        }));

        console.log('skillbase->', skill_base);
        this.setRouterSkillBase(skill_base);
      }
    },

    getSkillTemplate(bot) {
      if (bot.has_skill_base) {
        let selectedTemplate = this.templates.find(
          el => el.name == 'ecommerce',
        );
        // Actualizando value del template en store
        this.setTemplateValue(selectedTemplate);
      }
    },

    // smooth scroll
    scrollTo(millis) {
      this.$scrollTo(`#${this.$route.query.seccion}`, millis);
    },
  },
  beforeDestroy() {
    socket.removeListener('PUSH_DATA');
  },
};
</script>

<style lang="scss">
.swal2-toast-shown .swal2-container.swal2-top-end {
  padding: 1rem;
}
.swal2-popup.swal2-toast {
  padding: 0.75rem 1rem;
  box-shadow: 5px 5px 20px #deedff;
}
// .swal2-popup.swal2-toast.swal2-show {
//   background-color: #181818;
//   border: none
// }
// .swal2-html-container span {
//   color: #fff !important;
// }
</style>

<style lang="scss" scoped>
.layout {
  position: relative;
  display: grid;
  grid-template-columns: 65px 1fr;
  transition: 0.5s;
  height: 100vh;
  overflow-x: hidden;
  background-color: #fafafa;

  .item-1 {
    grid-column: 1;
    grid-row: 1 / span 2;

    @media (max-width: 640px) {
      grid-column: 0;
      visibility: hidden;
      grid-row: 0;

      &:not(.btn-menu) {
        visibility: visible;
      }
    }
  }
  .item-2 {
    display: relative;
    grid-column: 2;
    grid-row: 1;
    height: fit-content;
    margin: 0;
    border-bottom: 1px solid #e8e8e8;

    @media (max-width: 1024px) {
      grid-column: 1 / span 2;
    }
  }
  .item-3 {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
    height: calc(100vh - 80px);
    overflow-x: hidden;

    @media (max-width: 1024px) {
      grid-column: 1 / span 2;
    }
  }
}
</style>
