var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{key:_vm.logged,staticClass:"main-buttons"},[_c('div',{staticClass:"button-bots desktop"},[_c('div',{staticClass:"bots-box"},[(_vm.selected_bot)?_c('ValidateDesactiveBots'):_vm._e(),(_vm.scrollPoint > 0)?_c('span',{staticClass:"i-btn py-1 px-2 ml-3 mr-3",on:{"click":function($event){return _vm.scrolltoBots('left')}}},[_vm._v(_vm._s(`<-`))]):_vm._e(),_c('div',{ref:"total_width",class:_vm.manyBots ? 'slide_box' : 'bots-box',attrs:{"id":"slide_box"}},[_vm._l((_vm.ownerBots),function(bot,index){return _c('span',{key:index,class:{
            'text-state text-state-md': true,
            selected: bot.id == _vm.selected_bot.id,
            desactived: bot.id == _vm.selected_bot.id && bot.activated == false,
          },on:{"click":function($event){return _vm.selectedBot(bot)}}},[_vm._v(_vm._s(bot.bot_name)),(bot.id == _vm.selected_bot.id && bot.activated == false)?_c('i',{staticClass:"fas fa-times",on:{"click":_vm.deleteChatbot}}):_vm._e()])}),_c('router-link',{staticClass:"btn-dash btn-dash-sm my-2 mr-3",attrs:{"to":"/chatbot/new"}},[_vm._v("+")])],2),(_vm.manyBots && _vm.scrollPoint < _vm.setTotalWidth)?_c('span',{staticClass:"i-btn py-1 px-2 mr-3",on:{"click":function($event){return _vm.scrolltoBots('right')}}},[_vm._v(_vm._s(`->`))]):_vm._e()],1),(_vm.activeViewSetted)?_c('RolMenu',{attrs:{"mobile":false}}):_vm._e()],1),_c('div',{class:{
      'menu-mobile mobile': true,
      'no-icon-view': !_vm.activeViewSetted
    }},[_c('span',{staticClass:"i-btn i-menu",on:{"click":function($event){return _vm.setShowSidebarMobile(!_vm.showSidebarMobile)}}}),(_vm.ownerBots)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.mobile_selected_bot),expression:"mobile_selected_bot"}],attrs:{"id":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.mobile_selected_bot=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.selectedBot(_vm.mobile_selected_bot)}]}},_vm._l((_vm.ownerBots),function(bot,index){return _c('option',{key:index,domProps:{"value":bot}},[_vm._v(" "+_vm._s(bot.bot_name))])}),0):_vm._e(),(_vm.activeViewSetted)?_c('RolMenu',{attrs:{"mobile":true}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }