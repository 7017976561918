<template>
  <div :class="{'rol-menu': true, 'custom-btn': mobile}">
    <span
      v-if="!mobile"
      @click="chageBots"
      class="text-state text-state-md rol-menu mr-0"
    >
      <i class="fas fa-eye" />{{ changeView }}
    </span>
    <span
      v-else
      @click="chageBots"
      class="i-btn i-eye"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: ["mobile"],

  data() {
    return {
      local_active_view: 'my_bots',
      changeView: 'Ver chatbots asignados'
    };
  },

  methods: {
    ...mapMutations(['setActiveView']),

    chageBots() {
      if (this.local_active_view == 'my_bots') {
        this.local_active_view = 'assigned_bots';
        this.changeView = 'Ver chatbots propios';
        this.setActiveView(this.local_active_view);
      } else {
        this.local_active_view = 'my_bots';
        this.changeView = 'Ver chatbots asignados';
        this.setActiveView(this.local_active_view);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rol-menu {
  position: relative;
  width: fit-content !important;
  height: 100%;
  text-align: left !important;
  align-items: flex-start;
  margin: 0;

  i {
    margin-top: .25rem;
  }
}
.custom-btn {
  width: 100% !important;
  height: 100% !important;
  
  span {
    display: block;
    height: 100%;
  }
}
</style>
