var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"menu-box"},[_c('div',{staticClass:"sidebar"},[_c('img',{staticClass:"btn-menu i-btn px-3 py-2 mobile",attrs:{"src":"/img/icons/30-menu.svg","alt":""},on:{"click":function($event){return _vm.setShowSidebarMobile(!_vm.showSidebarMobile)}}}),(_vm.selected_bot)?_c('div',{staticClass:"box"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"mt-3 p-relative"},_vm._l((_vm.filteredItems),function(item){return _c('div',{key:item.id,staticClass:"p-relative",on:{"click":function($event){return _vm.showSubMenu(item)}}},[(item.path == '/databot-live' && _vm.new_message)?_c('span',{staticClass:"notification_dot"}):_vm._e(),(item.path && item.path.length > 0)?_c('router-link',{class:{
                'btn-item': true,
                'mb-5': item.name == 'Cuenta',
                'btn-item_active':
                  _vm.selected_item.name == item.name && item.name != 'Cuenta',
              },attrs:{"to":_vm.selected_bot.activated != false ? item.path : ''}},[(item.name != 'Cuenta')?_c('img',{attrs:{"src":item.icon,"alt":item.name}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSub),expression:"!showSub"}],staticClass:"tooltip_text-sm"},[_vm._v(_vm._s(item.name))])]):_c('span',{class:{
                'btn-item': true,
                'mb-5': item.name == 'Cuenta',
                'btn-item_active':
                  _vm.selected_item.name == item.name && item.name != 'Cuenta',
              }},[(item.name != 'Cuenta')?_c('img',{attrs:{"src":item.icon,"alt":item.name}}):_c('img',{staticClass:"databot-iso",attrs:{"src":_vm.user.user_avatar
                    ? _vm.user.user_avatar
                    : _vm.user.image || item.icon,"alt":_vm.user.name}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSub),expression:"!showSub"}],staticClass:"tooltip_text-sm"},[_vm._v(_vm._s(item.name))])])],1)}),0),(_vm.active_view == 'my_bots')?[_c('router-link',{staticClass:"btn-item",attrs:{"to":{
              name: 'dashboard-planes',
              query: {
                seccion: 'mejorar-plan',
                bot_id: _vm.selected_bot.id,
              },
            }}},[_c('img',{staticClass:"btn-update",attrs:{"src":"/img/icons/dashboard/10.2-upgrade.svg","alt":"upgrade"}}),_c('span',{staticClass:"tooltip_text-sm"},[_vm._v("Mejorar plan")])])]:_vm._e()],2),(_vm.selected_bot)?_c('SubMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSub),expression:"showSub"}],key:_vm.active_view,attrs:{"item":_vm.selected_item},on:{"show":function($event){_vm.showSub = false},"getShowImplement":function($event){_vm.show_implement = $event}}}):_vm._e(),_c('Implementacion',{attrs:{"show":_vm.show_implement,"bot_id":_vm.selected_bot.id,"bot_token":_vm.selected_bot.token},on:{"close":function($event){_vm.show_implement = false}}})],1):_vm._e()]),_c('MobileMenu',{attrs:{"user":_vm.user,"showSub":_vm.showSub,"show":_vm.showSidebarMobile,"active_view":_vm.active_view,"items":_vm.items},on:{"close":function($event){return _vm.setShowSidebarMobile($event)},"getShowImplementMobile":function($event){_vm.show_implement = $event},"showMobile":function($event){_vm.showSub = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }