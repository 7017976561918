import axios from 'axios';
import environment from '@/environment';
import dashboard_api from '@/dashboard_api';
import moment from 'moment';

function isObjEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
}

function addCustomScript(src, otherAttributes) {
  self.loading = true;

  const recaptchaScript = document.createElement('script');
  recaptchaScript.setAttribute('src', src);
  // agregando el resto de atributos
  for (const key in otherAttributes) {
    if (Object.hasOwnProperty.call(otherAttributes, key)) {
      const element = otherAttributes[key];
      recaptchaScript.setAttribute(key, element);
    }
  }
  recaptchaScript.async = true;
  document.head.appendChild(recaptchaScript);

  self.loading = false;
}

/**
 * Metodos para SICC
 */

function botSiccId() {
  // asignando id en qa y prod para chatbot sicc
  return window.location.origin === 'https://dashboard-front-qa.herokuapp.com'
    ? 541 // id sicc qa
    : process.env.NODE_ENV === 'development'
    ? 25 // id local / puedes modificar esto
    : 603; // id sicc prod
}

function getIndustriesFromLocalStorage() {
  return localStorageGetItem('sicc')
    ? JSON.parse(localStorageGetItem('sicc')).industries
    : [];
}

function getCompaniesFromLocalStorage() {
  return localStorageGetItem('sicc')
    ? JSON.parse(localStorageGetItem('sicc')).companies
    : [];
}

function hasActiveSoundNotifications() {
  return localStorageGetItem('userSounds')
    ? JSON.parse(localStorageGetItem('userSounds')).has_sound
    : false;
}

function waitForAudioRunning() {
  const audio = new Audio('/sounds/badumtss.mp3'); // sonido de prueba
  audio.volume = 0;
  const interval = setInterval(() => {
    const playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          clearInterval(interval);
        })
        .catch(() => {});
    }
  }, 300);
}

/**
 * @description Esta funcion se usa en WHATSAPP para calcular el tiempo restante
 * @param {Date} lastClientMessageDate Fecha del último mensaje recibido del cliente
 */

function whatsappTimeRemaining(lastClientMessageDate) {
  return (
    24 * 60 * 60 * 1000 -
    (Date.now() - new Date(lastClientMessageDate).getTime())
  );
}

function isImage(url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

/**
 * @description Esta función hace scroll hasta el final de la ventana del chat
 */

function scrollBottom() {
  let endOfChat = document.querySelector('.end-of-chat');
  if (endOfChat) {
    setTimeout(() => {
      endOfChat.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 0);
  }
}

function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

function random(min, max) {
  let newMin = Math.ceil(min);
  let newMax = Math.floor(max);
  return Math.floor(Math.random() * (newMax - newMin + 1)) + min;
}

function formatTemplateMessageName(text) {
  // eliminando caracteres especiales
  return removeAccents(text)
    .replace(/[`´~!@#$%^&*()|+\-=¿?;:'",.<>\{\}\[\]\\\/]/gi, '')
    .toLowerCase()
    .split(' ')
    .join('_');
}

/**
 *
 * @param {*} str cualquier cadena de texto
 * @returns Cadena de texto sin acentos
 */
function removeAccents(str) {
  var accents =
    'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  var accentsOut =
    'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
  str = str.split('');
  var strLen = str.length;
  var i;
  var x;
  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(str[i])) != -1) {
      str[i] = accentsOut[x];
    }
  }
  return str.join('');
}

function removeEmojis(str) {
  return str.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
}

function removeDuplicateObjects(array, uniqueField) {
  return array
    .filter(el => el[uniqueField])
    .reduce((unique, o) => {
      if (!unique.some(obj => obj[uniqueField] === o[uniqueField])) {
        unique.push(o);
      }
      return unique;
    }, []);
}

/**
 * @Description Retorna la plataforma ecommerce actual
 */
function getCurrentEcommercePlatform() {
  let platforms = [
    { name: 'shopify', domain: 'myshopify.com' },
    { name: 'jumpseller', domain: 'jumpseller.com' },
  ];
  let currentPlatform = platforms.find(
    platform =>
      platform.domain &&
      (window.location.href.includes(platform.domain) ||
        window.location.href.includes(platform.name)),
  );
  return currentPlatform ? currentPlatform.name : null;
}

function sortArrayObjects(arr, field, order) {
  return arr.sort((a, b) => {
    return order === -1 || order === 'desc'
      ? new Date(b[field]) - new Date(a[field])
      : new Date(a[field]) - new Date(b[field]);
  });
}

async function sync360DialogTemplateMessages(templates, botId) {
  let updatedTemplates = templates;
  let templates360 = (
    await axios.get(
      environment.socket_url +
        '/whatsapp/360dialog/template-messages?botId=' +
        botId,
    )
  ).data.payload.waba_templates;
  // buscando diferencias en estados (enviado, aprobado, rechazado, etc) para sincronizar
  for (const template of updatedTemplates) {
    // se verifica si existe en 360
    let template360 = templates360.find(el => el.name === template.name);
    if (template360) {
      if (template360.status !== template.status) {
        template.status = template360.status;
        dashboard_api.put('/payroll_messages/' + template.id, {
          payroll_message: { status: template360.status },
        });
      }
    }
  }
  return sortArrayObjects(updatedTemplates, 'created_at', 'desc');
}

function capitalizeFirstLetter(string) {
  return (
    String(string)
      .charAt(0)
      .toUpperCase() + String(string).slice(1)
  );
}

/**
 * @Description Return item from LocalStorage. If error, return null
 */
function localStorageGetItem(key) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // some error to log
  }
}

/**
 * @Description Remove item from LocalStorage. If error, return null
 */
function localStorageRemove(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // some error to log
  }
}

/**
 * @Description Set item to LocalStorage. If error, return null
 */
function localStorageSetItem(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {}
}

/**
 * @Description Query params from current URL
 */
function getUrlQueryParameters() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

function reverseString(str) {
  var newString = '';
  for (var i = str.length - 1; i >= 0; i--) {
    newString += str[i];
  }
  return newString;
}

/**
 *
 * @param {string} assistant_id Assistant id from bot
 * @returns {boolean} true if bot is LLM
 */
function checkIfBotLLM(assistant_id) {
  return (
    assistant_id.startsWith('rasa_') ||
    assistant_id.startsWith('llm_') ||
    reverseString(assistant_id).startsWith('asar_') ||
    reverseString(assistant_id).startsWith('mll_')
  );
}

function formatDateRelative(date) {
  let filter = moment(date).locale('es');

  return date ? filter.format('ll') + ' - ' + filter.format('LT') : 'ahora';
}

function insertSoporteBotDatabot() {
  // eliminando bot
  const iframes = document.querySelectorAll('iframe');
  for (let i = 0; i < iframes.length; i++) {
    iframes[i].parentNode.removeChild(iframes[i]);
  }
  // agregando nuevamente
  addCustomScript('https://databot-api.herokuapp.com/dist_files/databot.js', {
    id: '749',
    bot: 'TAIPSAVBQHMSMSNQ',
  });
}

export {
  isObjEmpty,
  addCustomScript,
  // METODOS SICC
  botSiccId,
  getIndustriesFromLocalStorage,
  getCompaniesFromLocalStorage,
  hasActiveSoundNotifications,
  waitForAudioRunning,
  whatsappTimeRemaining,
  isImage,
  scrollBottom,
  isNumeric,
  random,
  formatTemplateMessageName,
  removeEmojis,
  removeAccents,
  removeDuplicateObjects,
  getCurrentEcommercePlatform,
  sync360DialogTemplateMessages,
  sortArrayObjects,
  capitalizeFirstLetter,
  localStorageGetItem,
  localStorageRemove,
  localStorageSetItem,
  getUrlQueryParameters,
  reverseString,
  checkIfBotLLM,
  formatDateRelative,
  insertSoporteBotDatabot,
};
