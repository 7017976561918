import VueMomentLib from 'vue-moment-lib';
import Vue from 'vue';
import './registerServiceWorker';
import BCollapse from 'buefy';
import { VuejsDatatableFactory } from 'vuejs-datatable';
import Hotjar from 'vue-hotjar';
import VueAnalytics from 'vue-analytics';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// Google tag
import VueGtag from 'vue-gtag';
// Compartir rrss
import VueSocialSharing from 'vue-social-sharing';
// google tag manager
import VueGtm from 'vue-gtm';
// plugin para scroll
import VueScrollTo from 'vue-scrollto';
import { clpFormat } from './plugins/currency_formatter';
import store from './store/store';
import ArgonDashboard from './plugins/argon-dashboard';
import router from './router';
import App from './App.vue';
import {
  getCurrentEcommercePlatform,
  getUrlQueryParameters,
} from '@/utils/utils';
import startShopifyApp from '@/ecommerce-platforms/shopify';
// plugin personalizado para notification de sonido
import './plugins/soundNotification';
// sockets globales
import '@/plugins/sockets.js';
// localstorage
import '@/plugins/localStorage.js';
// plugin para generar uuid
import './plugins/uuid';
import './plugins/markdown';
// Comentar para error group
// Vue.use(VueGtag, {
//   config: { id: "UA-174270167-1" },
//   params: {
//     send_page_view: false,
//   },
// });
//  config: { id: "AW-622614762" },

// Este codigo causa error en consola
// Vue.use(VueGtm, {
//   id: "GTM-T2SWHF3",
//   queryParams: {
//     gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
//     gtm_preview: "env-4",
//     gtm_cookies_win: "x",
//   },
//   defer: false,
//   enabled: true,
//   debug: true,
//   loadScript: true,
//   vueRouter: router,
//   ignoredViews: [""],
// });

Vue.use(VueScrollTo);

Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

Vue.use(VuejsDatatableFactory);
// Vue.use(VueSwal)
Vue.use(VueSweetalert2);
Vue.use(BCollapse);

Vue.filter('clp', clpFormat);

Vue.use(Hotjar, {
  // id: "1781729",
  id: "3398056",
  snippetVersion: 6,
  isProduction: true,
});

// Vue.use(VueAnalytics, {
//   id: 'UA-160614602-1',
//   router
// });

Vue.use(VueMomentLib);

Vue.use(ArgonDashboard);

// Gestion de plataforma Ecommerce
const platform = getCurrentEcommercePlatform();
if (platform) {
  const params = getUrlQueryParameters();
  store.commit('ecommercePlatformsModule/setPlatform', platform); // initializing platform if exists
  store.commit('ecommercePlatformsModule/setPlatformParameters', params); // initializing platform if exists
  if (platform === 'shopify') {
    startShopifyApp(params.host, params.shop, params.iframe);
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
