<template>
  <section class="nuevo-agente">
    <SpinnerLoading v-if="loading" />
    <template v-else>
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-6 mb-5">
            <div class="card__box">
              <div class="mb-2">
                <router-link class="btn-text" to="/agentes"
                  ><i class="fas fa-arrow-left mr-2" />Agentes</router-link
                >
              </div>
              <h2 class="mb-4">Crear agente</h2>
              <div class="form-row">
                <div class="col-6 pr-2">
                  <label>Nombre</label>
                  <input placeholder="Nombre de agente" v-model="name" />
                </div>
                <div class="col-6 pl-2">
                  <label>Correo</label>
                  <input
                    placeholder="agente@empresa.cl"
                    v-model="email"
                    type="email"
                    required
                  />
                </div>
              </div>
              <label>Teléfono</label>
              <input placeholder="9 1234 5678" v-model="phone" />
              <div class="form-row mt-4">
                <div class="col-6 pl-2">
                  <label for="category">Área asignada</label>
                  <label
                    v-for="(item, index) in categories"
                    :key="item.id + index"
                    class="label-category"
                  >
                    <input
                      type="checkbox"
                      :id="item.name"
                      :value="item.name"
                      :disabled="category.includes('general') && item.name != 'general'"
                      v-model="category"
                      @change="filterSelectedCategory(category)"
                    />
                    <span>{{ capitalize(item.name) }}</span>
                  </label>
                </div>
                <div class="col-6 pr-2">
                  <label for="mostrarrole">Rol asignado</label>
                  <select
                    v-if="model"
                    name="mostrarrole"
                    v-model="model.rol_id"
                  >
                    <option value disabled>Seleccionar</option>
                    <option
                      v-for="role in roles"
                      :value="role.id"
                      :key="role.id"
                      >{{ role.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 pr-2">
                  <label>Contraseña</label>
                  <input
                    placeholder="*********"
                    type="password"
                    v-model="password"
                    required
                  />
                </div>
                <div class="col-6 pl-2">
                  <label>Confirmar contraseña</label>
                  <input
                    placeholder="*********"
                    type="password"
                    v-model="confirmPassword"
                    required
                  />
                </div>
              </div>
              <!-- <div class="d-flex">
                <div class="field buefy col-6 p-0 pr-2">
                  <div class="info_box my-4">
                    <div class="d-flex justify-content-between">
                      <label>Asignación automática</label>
                      <b-switch v-model="automaticAssignments" />
                    </div>
                    <p class="info">
                      Siempre recibir las conversaciones dependiendo del área
                      asignada
                    </p>
                  </div>
                </div>
                <div class="field buefy col-6 p-0 pl-2">
                  <div class="info_box my-4">
                    <div class="d-flex justify-content-between">
                      <label>
                        Derivar conversación
                      </label>
                      <b-switch v-model="assignments" />
                    </div>
                    <p class="info">
                      Este agente podrá derivar la conversación a otro
                    </p>
                  </div>
                </div>
              </div> -->
              <button @click="saveAgent" class="btn btn-base my-5">
                Guardar
              </button>
            </div>
          </div>
          <div class="col-md-4 mt-4">
            <label>Foto de perfil</label>
            <div>
              <div class="img_box">
                <img
                  :src="
                    model.foto_url
                      ? model.foto_url
                      : '/img/brand/0-perfil-agente.png'
                  "
                  alt="Foto de perfil"
                />
              </div>
            </div>
            <div class="input-img pt-4">
              <input type="file" @change="processFile($event)" />
              <p class="text__warning">
                La imagen no puede superar 1 MB de peso. Preferible formato
                cuadrado, 1:1 o similar sin fondo transparente
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import '../../assets/buefy_class.scss';
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import { capitalizeFirstLetter } from '@/utils/utils.js';

export default {
  data() {
    return {
      loading: false,
      roles: [],
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      category: ['general'],
      categories: [
        {
          id: 1,
          name: 'ventas',
        },
        {
          id: 2,
          name: 'facturación',
        },
        {
          id: 3,
          name: 'post-venta',
        },
        {
          id: 4,
          name: 'soporte',
        },
        {
          id: 5,
          name: 'general'
        }
      ],
      assignments: false,
      automaticAssignments: false,
      users: [],
      model: {
        rol_id: '',
        foto_url: '',
      },
      max_agent: 0,
      imageAvatar: null,
    };
  },
  methods: {
    // Al seleccionar General se deseleccionan los otros campos
    filterSelectedCategory(categories) {
      if (categories && categories.length > 1) {
        let item = categories.find(el => el == 'general');

        if (item) {
          this.category = this.category.filter(el => el == item);
        } 
      }
      console.log("this.category", this.category);
    },
    async getUsers() {
      this.loading = true;
      this.users = (
        await dashboard_api.get(`/userbot/user_for_bot/${this.selected_bot.id}`)
      ).data;
      this.getPlanAgents();
      this.loading = false;
    },

    async getRole(bot_id) {
      this.roles = (await dashboard_api.get(`/role/role_bot/${bot_id}`)).data;
    },
    formDataBody() {
      console.log("phone--->", this.phone);
      const data = new FormData();
      // const category = { category: this.category };
      data.append('agent[name]', this.name);
      data.append('agent[email]', this.email);
      data.append('agent[password]', this.password);
      data.append('agent[phone]', this.phone.toString().includes(" ") ? this.phone.replace(/\s/g, "") : this.phone);
      data.append('user_avatar', this.imageAvatar);
      // this.category.forEach(item => {
      //   data.append('agent[category][]', item);
      // });
      data.append('agent[assignments]', this.assignments);
      data.append('agent[automatic_assignments]', this.automaticAssignments);

      return data;
    },

    saveAgent() {
      const self = this;
      // this.expresion = /\w+@\w+\.+[a-z]/;
      self.error = '';

      if (self.name === '') {
        self.$swal({
          icon: 'error',
          title: 'Nombre del agente requerido',
        });
      } else if (self.email === '') {
        self.$swal({
          icon: 'error',
          title: 'Email del agente requerido',
        });
      } else if (self.password === '') {
        self.$swal({
          icon: 'error',
          title: 'Password requerido',
        });
      } else if (self.password.length < 6) {
        self.$swal({
          icon: 'error',
          title: 'El password debe tener mínimo 6 caracteres',
        });
      } else if (self.confirmPassword === '') {
        self.$swal({
          icon: 'error',
          title: 'Confirmar password requerido',
        });
      } else if (self.password != self.confirmPassword) {
        self.$swal({
          icon: 'error',
          title: 'Password no coinciden',
        });
      } else if (self.selected_bot.id === '') {
        self.$swal({
          icon: 'error',
          title: 'El agente debe estar vinculado a un bot',
        });
      } else if (self.model.rol_id === '') {
        self.$swal({
          icon: 'error',
          title: 'El agente debe tener un rol',
        });
      } else if (self.category === false) {
        self.$swal({
          icon: 'error',
          title: 'El agente debe tener un área asignada',
        });
      } else if (self.users.length >= self.max_agent) {
        self.$swal({
          icon: 'error',
          title:
            'lo sentimos, pero a superado la cantidad maxima de agentes del plan de este bot',
        });
      } else {
        // self.loading = true;
        self.$swal({
          icon: 'info',
          title: 'Estamos creando y vinculando al agente...',
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        self.$swal.showLoading();

        const formData = self.formDataBody();

        dashboard_api
          .post('/agents', formData, {
            headers: { Accept: 'application/vnd.v2+json' },
          })
          .then(result => {
            dashboard_api
              .post('/userbot/createuserbot', {
                user_bot: {
                  user_id: result.data.id,
                  bot_id: self.selected_bot.id,
                  role_id: self.model.rol_id,
                  category: self.category
                }
              })
              .then(result => {
                self.model.rol_id = '';
              });

            if (result.status == 201) {
              self.$swal({
                icon: 'success',
                title: 'Agente guardado y vinculado',
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 800,
              });
            }
            self.name = '';
            self.email = '';
            self.password = '';
            self.confirmPassword = '';
            self.phone = '';
            self.model.foto_url = '';
            self.category = [];
            self.assignments = false;
            self.automaticAssignments = false;
            self.loading = false;
          })
          .catch(err => {
            if (err == 'Error: Unprocessable Entity') {
              self.$swal({
                icon: 'error',
                title:
                  'El email ya lo esta usando otro agente, puede vincular al agente en el boton asociar agente.',
              });
              self.loading = false;
            } else {
              self.$swal({
                icon: 'error',
                title: 'El correo ya existe',
                text: 'Para vincular este correo debes ir a Asociar agente.',
              });
              self.loading = false;
            }
          });
      }
    },

    processFile(event) {
      const self = this;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      self.imageAvatar = event.target.files[0];
      reader.onload = function () {
        self.model.foto_url = reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },

    async getPlanAgents() {
      this.max_agent = (
        await dashboard_api.get(`bot/bot_agents/${this.selected_bot.id}`)
      ).data.agents;
    },

    capitalize(val) {
      return capitalizeFirstLetter(val);
    }
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getRole(val.id);
        this.getUsers();
      }
    },
  },
  computed: {
    ...mapState(['user', 'selected_bot']),
  },
  // Obtener los bot mediante los planes del user
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.nuevo-agente {
  .card__box {
    padding: 0 2rem;
  }
  input {
    &[type='file']::-webkit-file-upload-button {
      align-items: center;
      background: #b2b2b2;
      color: #fff;
      border: none;
      border-radius: 3px;
      font-size: 12px;
    }
  }
  .text__warning {
    font-size: 12px;
  }
  .img_box {
    text-align: center;
    width: 300px;
    height: 300px;

    img {
      border-radius: 10px;
      height: 100%;
      width: 300px;
      border: 1px solid #f2f2f2;
      margin: 1.1rem auto;
    }
  }
  .container {
    margin-bottom: 50px;
  }
  select {
    width: 100%;
  }
}
.info_box {
  height: 80%;
}
.input-img {
  width: 300px;
}
.label-category {
  font-weight: normal;
  width: 100%;
  display: flex;

  input {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  span {
    margin-left: 5px;
  }
}
</style>
