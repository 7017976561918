<template>
  <section :key="logged" class="main-buttons">
    <div class="button-bots desktop">
      <div class="bots-box">
        <ValidateDesactiveBots v-if="selected_bot" />
        <span
          v-if="scrollPoint > 0"
          @click="scrolltoBots('left')"
          class="i-btn py-1 px-2 ml-3 mr-3"
        >{{`<-`}}</span>
        <div
          :class="
            manyBots ? 'slide_box' : 'bots-box'
          "
          id="slide_box"
          ref="total_width"
        >
          <span
            v-for="(bot, index) in ownerBots"
            :key="index"
            :class="{
              'text-state text-state-md': true,
              selected: bot.id == selected_bot.id,
              desactived: bot.id == selected_bot.id && bot.activated == false,
            }"
            @click="selectedBot(bot)"
            >{{ bot.bot_name
            }}<i
              v-if="bot.id == selected_bot.id && bot.activated == false"
              @click="deleteChatbot"
              class="fas fa-times"
          /></span>
          <router-link to="/chatbot/new" class="btn-dash btn-dash-sm my-2 mr-3"
            >+</router-link>
        </div>
        <span
          v-if="manyBots && scrollPoint < setTotalWidth"
          @click="scrolltoBots('right')"
          class="i-btn py-1 px-2 mr-3"
        >{{`->`}}</span>
      </div>
      <RolMenu :mobile="false" v-if="activeViewSetted" />
    </div>
    <!-- Menu mobile -->
    <div
      :class="{
        'menu-mobile mobile': true,
        'no-icon-view': !activeViewSetted
      }"
    >
      <span @click="setShowSidebarMobile(!showSidebarMobile)" class="i-btn i-menu" />
      <select
        v-if="ownerBots"
        v-model="mobile_selected_bot"
        @change="selectedBot(mobile_selected_bot)"
        id=""
      >
        <option
          v-for="(bot, index) in ownerBots"
          :key="index"
          :value="bot"
        >
        {{ bot.bot_name }}</option>
      </select>
      <RolMenu :mobile="true" v-if="activeViewSetted" />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import dashboard_api from '../dashboard_api.js';
import ValidateDesactiveBots from '@/components/ValidateDesactiveBots';
import DaysLeft from '@/components/DaysLeft';
import RolMenu from '@/components/SidebarPlugin/RolMenu';

export default {
  props: ['activeViewSetted'],

  components: {
    ValidateDesactiveBots,
    DaysLeft,
    RolMenu,
  },
  data() {
    return {
      plan_name: '',
      bots: [],
      loading: false,
      showSidebar: false,
      mobile_selected_bot: {},
      scrollPoint: 0,
      setTotalWidth: 0
    };
  },
  computed: {
    ...mapState([
      'logged',
      'user',
      'active_view',
      'ownerBots',
      'selected_bot',
      'showSidebarMobile'
    ]),
    manyBots() {
      return this.ownerBots.length > 8;
    }
  },

  methods: {
    ...mapMutations(["setSelectedBot", "setPlanName", "setShowSidebarMobile"]),

    scrolltoBots(to) {
      if (to == "left") {
        if (this.scrollPoint < 0) {
          this.scrollPoint = document.getElementById("slide_box").scrollLeft = 0;
        } else {
          this.scrollPoint = document.getElementById("slide_box").scrollLeft -= 136;
        }
      } else {
        this.scrollPoint = document.getElementById("slide_box").scrollLeft += 136;
      }
    },
    async getBotPlan(bot) {
      try {
        this.plan_name = (
          await dashboard_api.get(`/client_subscriptions/by_bot_id/${bot.id}`)
        ).data.admissible_plan.name;
      } catch (error) {
        this.plan_name = '';
      }
      this.setPlanName(this.plan_name);
    },

    async selectedBot(bot) {
      this.setSelectedBot(bot);
      await this.getBotPlan(bot);

      let show;
      setTimeout(() => {
        if (this.activeViewSetted == false) {
          bot ? (show = true) : (show = false);
          this.$emit('letShowData', show);
        }
      }, 5000);
    },

    async getBots() {
      const self = this;
      const owner = self.active_view === 'my_bots';
      self.loading = true;
      self.$emit('changeLoading', self.loading);
      let resp = null;

      // let resp = (await dashboard_api.get(`/bot/list/${self.user.id}?owner=${owner}`)).data;
      if (self.active_view == 'my_bots')
        resp = (await dashboard_api.get(`/bot/show_owned_bots/${self.user.id}`))
          .data;
      else
        resp = (
          await dashboard_api.get(`/bot/show_assigned_bots/${self.user.id}`)
        ).data;

      if (self.filterShow) {
        // bots filtrados por business show
        self.bots = resp.filter((el) => self.evaluateToShow(el.id));
      } else {
        self.bots = resp;
        self.bots.sort((a, b) => (a.activated > b.activated ? -1 : 1));
      }
      // console.log('bots->', self.bots);
      self.$store.commit('setOwnerBots', self.bots);
      await self.selectedBot(self.bots[0]);
      self.loading = false;
      self.$emit('changeLoading', self.loading);
      self.getWidthPerBot(self.bots)
    },

    getWidthPerBot(bots) {
      let responsive_box = this.$refs.total_width.clientWidth;
      let width = 0;
      let totalBots = bots.length;
      let overFlowBots = 0;

      if (bots && responsive_box == 544) {
        overFlowBots = (totalBots * 136) - (136 * 4);
        width = overFlowBots + 51;

        this.setTotalWidth = width;
      } else {
        overFlowBots = (totalBots * 136) - (136 * 8);
        width = overFlowBots + 51;

        this.setTotalWidth = width;
      }
    },

    evaluateToShow(botId) {
      let showBot = true;
      return showBot;
    },
    deleteChatbot() {
      const self = this;
      const owner = self.active_view == 'my_bots';

      if (self.ownerBots.length > 1) {
        self
          .$swal({
            title: `${
              owner ? '¿Eliminar chatbot?' : '¿Desvincular del chatbot?'
            }`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Cancelar',
            cancelButtonText: `${owner ? 'Eliminar' : 'Devincular'}`,
          })
          .then((result) => {
            // invertido para que el usuario no borre el bot
            if (!result.isConfirmed) {
              if (self.active_view != 'assigned_bots') {
                // Se cancela el plan primero, si no da error se prosigue con eliminar el bot

                if (self.selected_bot.client_subscription == null) {
                  dashboard_api
                    .delete(`/bot/${self.selected_bot.id}`)
                    .then((data) => {
                      console.log('1-data->', data);
                      self.$swal({
                        icon: 'success',
                        title: 'El chatbot se eliminó correctamente.',
                        timer: 1000,
                        showConfirmButton: false,
                      });

                      let deleteBot = self.ownerBots.findIndex(
                        (el) => el.id == self.selected_bot.id,
                      );

                      self.ownerBots.splice(deleteBot, 1);

                      self.$store.commit('setOwnerBots', self.ownerBots);
                      self.$store.commit('setSelectedBot', self.ownerBots[0]);
                    });
                } else {
                  dashboard_api
                    .post('/flow_requests/cancel_subscription', {
                      id: self.selected_bot.client_subscription.id,
                    })
                    .then((cancelSubscriptionResponse) => {
                      if (cancelSubscriptionResponse.status == 800) {
                        self.$swal({
                          icon: 'error',
                          title:
                            'El chatbot se no se pudo eliminar correctamente, por favor contacta a soporte.',
                        });
                      } else {
                        dashboard_api
                          .delete(`/bot/${self.selected_bot.id}`)
                          .then((data) => {
                            console.log('2-data->', data);
                            self.$swal({
                              icon: 'success',
                              title: 'El chatbot se eliminó correctamente.',
                              timer: 1000,
                              showConfirmButton: false,
                            });
                            let newBots = self.ownerBots;

                            newBots.forEach((bot, index, array) => {
                              if (bot.id == self.selected_bot.id) {
                                array.splice(index, 1);
                              }
                            });
                            self.$store.commit('setOwnerBots', newBots);
                            self.$store.commit(
                              'setSelectedBot',
                              self.ownerBots[0],
                            );
                          });
                      }
                    })
                    .catch((error) => console.error('error > ', error));
                }
              } else {
                dashboard_api
                  .delete(
                    `/userbot/unlink_bot/${self.user.id}/${self.selected_bot.id}`,
                  )
                  .then((data) => {
                    self.$swal({
                      icon: 'success',
                      title: 'El Chatbot se desvinculó exitosamente',
                    });
                    self.bots.forEach((bot, index, array) => {
                      if (bot.id == self.selected_bot.id) {
                        array.splice(index, 1);
                      }
                    });
                  });
                location.reload();
              }
            }
          });
      } else {
        self.$swal({
          icon: 'error',
          title: 'Tu cuenta debe tener al menos un chatbot',
        });
      }
    },
  },

  watch: {
    active_view: {
      immediate: true,
      handler(val) {
        if (this.logged == 'true') {
          this.getBots();
        }
      },
    },
    selected_bot(val) {
      //
      this.mobile_selected_bot = this.selected_bot;
      if (this.$route.query.seccion == 'mejorar-plan') {
        this.$router.push({
          name: 'dashboard-planes',
          query: {
            seccion: 'mejorar-plan',
            bot_id: val.id,
          },
        });
      }
      if (this.$route.name == 'detail-lead') {
        this.$router.push({ name: 'clients-show' });
      }
      if (this.$route.name == 'dashboard-checkout') {
        this.$router.push({
          name: 'dashboard-checkout',
          query: {
            section: this.$route.query.section,
            plan: this.$route.query.plan,
            name: this.$route.query.name,
            period: this.$route.query.period,
            bot_id: val.id,
          },
        });
      }
      // if (this.$route.name == 'mensajes-plantilla') {
      //   this.$router.push({ name: 'whatsapp-business' });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-buttons {
  display: flex;
  align-items: center;
  max-width: 100%;
  justify-content: space-between;
  width: 100%;
  .button-bots {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    margin: 1rem 0;
    padding: 0 2rem;
    z-index: 0;
    height: fit-content;
    width: 100%;

    @media (max-width: 960px) {
      display: block;
      justify-content: flex-start;
      height: auto;
    }
    @media (max-width: 640px) {
      padding: 0 0.5rem;
    }

    .bots-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 1279px) {
        width: 100%;
      }
    }

    .slide_box {
      display: flex;
      max-width: 1224px;
      width: 100%;
      overflow-x: scroll;
      scroll-behavior: smooth;

      @media (max-width: 1600px) {
        max-width: 544px;
      }
      
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }
    }
    .text-state-md {
      position: relative;
      text-align: center;
      min-width: 120px;
      margin: 0.5rem 1rem 0.5rem 0;
      z-index: 0;

      @media (max-width: 768px) {
        width: calc(33% - 1rem);
        margin: 0.5rem;
      }
      @media (max-width: 480px) {
        width: calc(50% - 1rem);
      }
      i {
        position: absolute;
        color: #b2b2b2;
        padding: 0.25rem 0.35rem;
        border: 1px solid #ff6565;
        background-color: #fff;
        color: #ff6565 !important;
        border-radius: 0.25rem;
        font-size: 0.5rem;
        right: -2px;
        z-index: 9;
        transition: 0.5s;
        outline: 2px solid #fff;
      }
    }
    .btn-dash-sm {
      margin: 0;

      @media (max-width: 960px) {
        margin: 0.5rem;
      }
    }
    .selected {
      transition: 0.5s;
      border-color: #2981ef;
      background-color: #fff;
      color: #2981ef !important;
    }
    .desactived {
      transition: 0.5s;
      border-color: #ff6565 !important;
      color: #ff6565 !important;
    }
  }
  .menu-mobile {
    display: grid;
    grid-template-columns: 40px 2fr 40px;
    column-gap: 1rem;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
    
    @media (max-width: 375px) {
      padding: 1rem;
    }
    span {
      width: 100%;
      height: 100%;
    }
    select {
      width: 100%;
      display: inline-block;
      justify-content: center;
      align-self: center;
      -webkit-align-self: center;
      text-align: center;
      font-size: 14px;
      padding: .5rem;
      margin-bottom: 0;
    }
  }
  .no-icon-view {
    grid-template-columns: 40px 2fr;
  }
  .desktop {
    @media (max-width: 1024px) {
      display: none !important;
    }
  }
  .mobile {
    @media (min-width: 1025px) {
      display: none !important;
    }
  }
}
</style>
