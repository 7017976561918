<template>
  <section class="menu-box">
    <div class="sidebar">
      <img
        @click="setShowSidebarMobile(!showSidebarMobile)"
        class="btn-menu i-btn px-3 py-2 mobile"
        src="/img/icons/30-menu.svg"
        alt=""
      />
      <div v-if="selected_bot" class="box">
        <div class="d-flex flex-column align-items-center">
          <div class="mt-3 p-relative">
            <div
              v-for="item in filteredItems"
              :key="item.id"
              @click="showSubMenu(item)"
              class="p-relative"
            >
              <span
                v-if="item.path == '/databot-live' && new_message"
                class="notification_dot"
              ></span>
              <router-link
                v-if="item.path && item.path.length > 0"
                :class="{
                  'btn-item': true,
                  'mb-5': item.name == 'Cuenta',
                  'btn-item_active':
                    selected_item.name == item.name && item.name != 'Cuenta',
                }"
                :to="selected_bot.activated != false ? item.path : ''"
              >
                <img
                  v-if="item.name != 'Cuenta'"
                  :src="item.icon"
                  :alt="item.name"
                />
                <span v-show="!showSub" class="tooltip_text-sm">{{
                  item.name
                }}</span>
              </router-link>
              <!-- items sin path no son link - para que no cambie la vista del skillbase -->
              <span
                v-else
                :class="{
                  'btn-item': true,
                  'mb-5': item.name == 'Cuenta',
                  'btn-item_active':
                    selected_item.name == item.name && item.name != 'Cuenta',
                }"
              >
                <img
                  v-if="item.name != 'Cuenta'"
                  :src="item.icon"
                  :alt="item.name"
                />
                <img
                  v-else
                  class="databot-iso"
                  :src="
                    user.user_avatar
                      ? user.user_avatar
                      : user.image || item.icon
                  "
                  :alt="user.name"
                />
                <span v-show="!showSub" class="tooltip_text-sm">{{
                  item.name
                }}</span>
              </span>
            </div>
          </div>
          <template v-if="active_view == 'my_bots'">
            <router-link
              class="btn-item"
              :to="{
                name: 'dashboard-planes',
                query: {
                  seccion: 'mejorar-plan',
                  bot_id: selected_bot.id,
                },
              }"
              ><img
                class="btn-update"
                src="/img/icons/dashboard/10.2-upgrade.svg"
                alt="upgrade"
              /><span class="tooltip_text-sm">Mejorar plan</span></router-link
            >
          </template>
        </div>
        <SubMenu
          v-if="selected_bot"
          v-show="showSub"
          :key="active_view"
          :item="selected_item"
          @show="showSub = false"
          @getShowImplement="show_implement = $event"
        />
        <Implementacion
          :show="show_implement"
          :bot_id="selected_bot.id"
          :bot_token="selected_bot.token"
          @close="show_implement = false"
        />
      </div>
    </div>
    <MobileMenu
      @close="setShowSidebarMobile($event)"
      @getShowImplementMobile="show_implement = $event"
      @showMobile="showSub = false"
      :user="user"
      :showSub="showSub"
      :show="showSidebarMobile"
      :active_view="active_view"
      :items="items"
    />
  </section>
</template>

<script>
import Implementacion from '@/components/Implementacion';
import { mapMutations, mapState } from 'vuex';
import dashboard_api from '../../dashboard_api.js';
import SubMenu from './SubMenu';
import MobileMenu from '@/components/mobile-menu/MobileMenu';

export default {
  name: 'sidebar',

  components: {
    SubMenu,
    Implementacion,
    MobileMenu,
  },
  data() {
    return {
      url_shop: '',
      show_implement: false,
      user_bots: [],
      user_plans: [],
      plans: [],
      show_select_bots_type: false,
      local_active_view: '',
      showSub: false,
      selected_item: {},
      showSidebar: false,
      items: [
        {
          name: 'Cuenta',
          path: '',
          icon: '/img/icons/dashboard/0-user-profile.svg',
          sub_menu: true,
        },
        {
          name: 'Dashboard',
          path: '/dashboard',
          icon: '/img/icons/dashboard/1-dashboard.svg',
          sub_menu: false,
        },
        {
          name: 'Chatbot',
          path: '',
          icon: '/img/icons/dashboard/2-chatbot.svg',
          sub_menu: true,
        },
        {
          name: 'Databot Center',
          path: '',
          icon: '/img/icons/dashboard/3-skill.svg',
          sub_menu: true,
        },
        {
          name: 'Historial de conversaciones',
          path: '/historial-conversaciones',
          icon: '/img/icons/dashboard/4-conversaciones.svg',
          sub_menu: false,
        },
        {
          name: 'Clientes',
          path: '/clients',
          icon: '/img/icons/dashboard/5-leads.svg',
          sub_menu: false,
        },
        {
          name: 'Databot Live',
          path: '/databot-live',
          icon: '/img/icons/dashboard/6-dlive.svg',
          sub_menu: false,
        },
        {
          name: 'WhatsApp',
          path: '',
          icon: '/img/icons/dashboard/7-whatsapp.svg',
          sub_menu: true,
        },
        {
          name: 'Agentes',
          path: '/agentes',
          icon: '/img/icons/dashboard/8-agentes.svg',
          sub_menu: false,
        },
        {
          name: 'Integraciones',
          path: '/integraciones',
          icon: '/img/icons/dashboard/9-integraciones.svg',
          sub_menu: false,
        },
        // {
        //   name: 'Incidencias',
        //   path: '/incidencias',
        //   icon: '/img/icons/dashboard/11-report.svg',
        //   sub_menu: false,
        // },
      ],
    };
  },

  mounted() {
    this.getPlans();
    this.getUserPlan();
    this.itemfilterValidation();
  },

  watch: {
    local_active_view(event) {
      this.setActiveView(event);
    },
    active_view(val) {
      this.itemfilterValidation();
    },
    user: {
      immediate: true,
      handler(val) {
        this.validatePlatform(val.id);
      },
    },
  },

  computed: {
    ...mapState([
      'user',
      'active_view',
      'selected_bot',
      'platform_name',
      'new_message',
      'showSidebarMobile',
    ]),

    filteredItems() {
      let restrictions = ['Integraciones', 'Agentes'];

      return this.active_view == 'my_bots'
        ? this.items
        : this.items.filter(el => !restrictions.includes(el.name));
    },
  },

  methods: {
    ...mapMutations([
      'logout',
      'setActiveView',
      'getPlatformName',
      'setNewMessage',
      'setShowSidebarMobile',
    ]),

    resetNewMessage(name) {
      name == 'Databot Live' ? this.setNewMessage(false) : '';
    },

    async validatePlatform(user_id) {
      if (!this.platform_name) {
        this.url_shop = (
          await dashboard_api.get(`user_data/${user_id}`)
        ).data.origin_ecommerce.url_origin;

        let origins = [{ name: 'shopify' }, { name: 'jumpseller' }];

        if (this.url_shop && this.url_shop.length > 0) {
          this.platform = origins.find(el =>
            this.url_shop.includes(el.name),
          ).name;

          console.log('entre', this.platform);
        } else {
          this.platform = 'databot';
        }
        this.getPlatformName(this.platform);
      }
      this.itemfilterValidation();
    },

    itemfilterValidation() {
      if (this.platform_name == 'shopify') {
        if (this.active_view == 'my_bots') {
          let item = this.items.findIndex(el => el.name == 'Integraciones');
          // console.log('item->', item);
          this.items.splice(item, 1);
        } else {
          this.items.push({
            name: 'Integraciones',
            path: '/integraciones',
            icon: '/img/icons/dashboard/9-integraciones.svg',
            sub_menu: false,
          });
        }
      } else {
        this.items;
      }
    },

    blockDesactivatedBot() {
      this.$swal({
        icon: 'info',
        title: 'Activa tu chatbot con un plan.',
        text: 'Para seguir ocupando este chatbot debes asignar un plan pagado.',
      });

      this.$router.push({
        name: 'dashboard-planes',
        query: { seccion: 'activar', bot_id: this.selected_bot.id },
      });
    },

    showSubMenu(item) {
      if (this.selected_bot && this.selected_bot.activated == false) {
        this.blockDesactivatedBot();
      } else {
        this.selected_item = item;

        if (this.showSub == false) {
          if (item.sub_menu == true) {
            this.showSub = true;
          } else this.showSub = false;
        } else if (item.sub_menu == true) {
          this.showSub = true;
        } else {
          this.showSub = false;
        }
      }
      this.resetNewMessage(item.name);
    },

    async getUserPlan() {
      this.user_bots = (
        await dashboard_api.get(`/userbot/show_bots_for_user/${this.user.id}`)
      ).data;

      this.user_plans = (
        await dashboard_api.get(`/bot/show_owned_bots/${this.user.id}`)
      ).data;

      // console.log('user_bots->', this.user_bots);
      // console.log('user_plans->', this.user_plans);

      if (this.user_plans.length > 0 && this.user_bots.length > 0) {
        this.show_select_bots_type = true;
        this.local_active_view = 'my_bots';
      } else {
        this.show_select_bots_type = false;
        if (this.user_plans.length > 0) this.local_active_view = 'my_bots';
        else this.local_active_view = 'assigned_bots';
      }
      this.$emit('activeViewSetted', this.show_select_bots_type);
    },

    async getPlans() {
      this.plans = (
        await dashboard_api.get(
          `/client_subscriptions/by_user_id/${this.user.id}/false`,
        )
      ).data;

      // console.log('this.plans sidebar', this.plans);
    },

    notifications() {
      const self = this;

      webpushr('fetch_id', sid => {
        if (sid) {
          dashboard_api
            .post('/push_subscribers', {
              push_subscriber: {
                user_id: self.user.id,
                sid,
                active: true,
              },
            })
            .then(response => {
              // console.log("response -> ", response);
            })
            .catch(error => {
              console.log('error -> ', error);
            });
        } else {
          console.log('no sid');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-box {
  height: 100%;
  .sidebar {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    transition: 0.5s ease all;
    background-color: #181818;
    animation-name: first-right-slide;
    animation-duration: 0.5s;

    @media (max-width: 1024px) {
      opacity: 0;
      visibility: hidden;
    }
    .box {
      position: sticky;
      top: 0;
    }

    .databot-iso {
      object-fit: cover;
      object-position: center;
      margin: 1.25rem auto !important;
      border-radius: 50rem;
      width: 45px !important;
      height: 45px !important;
      align-items: center;
      transition: 0.5s;
      cursor: pointer;

      &:hover {
        transition: 0.5s;
        transform: scale(1.1);

        + .tooltip_text-sm {
          visibility: visible;
          transition: 0.25s;
          opacity: 1;
          z-index: 150 !important;
        }
      }
    }
    .btn-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      transition: 0.5s;
      margin: 0.75rem auto;
      border-radius: 20rem;
      cursor: pointer;

      img {
        width: 1.4rem;
        transition: 0.5s;
      }

      &:hover {
        transition: 0.5s;
        background-color: #333;

        .tooltip_text-sm {
          visibility: visible;
          transition: 0.25s;
          opacity: 1;
          z-index: 150 !important;
        }

        img {
          transition: 0.5s;
          transform: scale(1.1);
        }
      }
    }
    .btn-item_active {
      padding: 0.5rem;
      border-radius: 50%;
      transition: 0.5s;
      background-color: #333;
    }
    .btn-update {
      width: 1.75rem !important;
    }
  }
  .btn-menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    left: 100%;
    right: 0;
    color: #fff;
    font-size: 1rem;
    width: 30px;
    height: 50px;
    padding: 0.5rem;
    border-radius: 0 0.5rem 0.5rem 0;
    background-color: #181818;
    z-index: 15;

    @media (max-width: 640px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }
  .show_sidebar {
    @media (max-width: 640px) {
      opacity: 1;
      visibility: visible;
    }
  }
  .move-right {
    @media (max-width: 640px) {
      animation-name: first-right-slide;
      animation-duration: 0.25s;
      left: 65px;
    }
  }
  .notification_dot {
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    border-radius: 2rem;
    background-color: #f5365c;
    top: 0;
    right: 0;
    z-index: 5;
    border: 2px solid #181818;
  }
}
</style>