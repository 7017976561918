<template>
  <section class="sub_menu-lvl3">
    <div class="d-flex pl-3" v-if="info.name == 'Plantillas'">
      <span
        class="d-flex align-items-center mb-2"
        v-for="(item, index) in filterItems()"
        :key="index"
      >
        <span
          @click="changeTemplate(item)"
          class="i-base i-btn i-btn-sm i-btn-lg my-2"
          :class="{
            'i-cart': item.name == 'ecommerce',
            'i-idea': item.name == 'generica',
            'btn-select-active': selected_template.name == item.name,
          }"
          :title="item.label"
        />
        <!-- <span class="tooltip_text-sm">{{
          item.label
        }}</span> -->
      </span>
    </div>
    <!-- <router-link
      v-else
      class="item-link item-link-sm"
      :to="`${item.path}?name=${item.original_name}`"
      >{{ item.name }}
    </router-link> -->
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: ['info'],

  data() {
    return {
      selected_template: {},
    };
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getSkillTemplate(val);
      },
    },
  },
  computed: {
    ...mapState(['selected_bot', "skill_template"]),
    templates() {
      return this.$store.state.templates;
    },
  },
  methods: {
    ...mapMutations(['setTemplateValue', 'setRouterSkillBase']),

    changeTemplate(template) {
      this.$swal({
        title: '¿Estás seguro que deseas cambiar plantilla?',
        text: 'Se borrarán todas las interacciones precargadas que hayas configurado',
        icon: 'warning',
        showCancelButton: true,
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Confirmar',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(async result => {
        if (result.isConfirmed) {
          console.log("BOT_ID, TEMPLATE: ");
          console.log({
                  bot_id: this.selected_bot.id,
                  template: this.skill_template.value
                });
          this.selected_template = template;
          if (this.selected_template !== this.skill_template.value) {
            // borrar todo lo copiado desde la plantilla de origen
            await Promise.all([
              await this.$store.dispatch(
                'dtClientIntentsModule/deleteManyTemplateIntents',
                {
                  bot_id: this.selected_bot.id,
                  template: this.skill_template.value
                }
              ),
              await this.$store.dispatch(
                'dtClientNodesModule/deleteManyTemplateNodes',
                {
                  bot_id: this.selected_bot.id,
                  template: this.skill_template.value
                }
              ),
            ]);
           }
          // Actualizando value del template en store
          this.setTemplateValue(this.selected_template);
          await Promise.all([
          this.$store.dispatch('dtClientIntentsModule/intentComplete', {
            template: this.selected_template,
            bot_id: this.selected_bot.id,
          }),
          this.$store.dispatch('dtClientNodesModule/nodeComplete', {
            template: this.selected_template,
            bot_id: this.selected_bot.id,
          }),
          this.$store.dispatch('dtClientEntitiesModule/entityCopyTemplate', {
            template: this.selected_template,
            bot_id: this.selected_bot.id,
          }),
        ]);
          return this.$swal({
            icon: 'info',
            title: 'Plantilla cambiada',
            text: `Has cambiado a la plantilla ${this.selected_template.label}`,
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
    },
    getSkillTemplate(bot) {
      // console.log('HAS SKILL BASE: ', bot.has_skill_base);
      // console.log('TEMPLATE: ', bot.template);
      // console.log('SELECTED TEMPLATE: ', this.selected_template);
      if (bot.has_skill_base) {
        this.selected_template = this.templates.find(
          el => el.name == 'ecommerce',
        );
      }
      // Actualizando value del template en store
      this.setTemplateValue(this.selected_template);
    },
    filterItems() {
      if (this.info) {
        switch (this.info.name) {
          case 'Plantillas':
            return this.templates;
            break;
          // case 'Precargadas':
          //   return this.skill_base;
          //   break;
          default:
            return [];
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sub_menu-lvl3 {
  width: 100%;
  background-color: #f8fbff;

  .item-link-sm {
    justify-content: flex-start;

    &:hover {
      color: #2981ef;
      background-color: #f8fbff;
    }
  }
  .i-base:hover {
    + .tooltip_text-sm {
      visibility: visible;
      transition: 0.25s;
      opacity: 1;
      z-index: 150 !important;
    }
  }
}
</style>
