import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from '@/layout/DashboardLayout';
import AuthLayout from '@/layout/AuthLayout';
import IntegrationLayout from '@/layout/IntegrationLayout';
import store from '@/store/store.js';

Vue.use(Router);

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  base: decodeURI('/'),
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          meta: { redirectRegister: true },
          component: () =>
            import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: '/registro',
          name: 'register',
          meta: { redirectRegister: true },
          component: () =>
            import(/* webpackChunkName: "reg" */ './views/Auth/registro.vue'),
        },
        {
          path: '/password_resets/:token',
          name: 'password_resets',
          component: () =>
            import(/* webpackChunkName: "pass" */ './views/Password.vue'),
        },
      ],
    },
    {
      path: '/',
      // redirect: "jumpseller-login",
      component: IntegrationLayout,
      children: [
        // {
        //   path: "/integration-login",
        //   name: "integration-login",
        //   component: () =>
        //     import(/* webpackChunkName: "conversaciones" */ "./views/Integraciones/login.vue"),
        // },
        // {
        //   path: "/integration-register",
        //   name: "integration-register",
        //   component: () =>
        //     import(/* webpackChunkName: "conversaciones" */ "./views/Integraciones/registro.vue"),
        // },
        {
          path: '/indexjumpseller',
          name: 'indexjumpseller',
          component: () =>
            import(
              /* webpackChunkName: "conversaciones" */ './views/Integraciones/index.vue'
            ),
        },
        // {
        //   path: "/integration-firstbot",
        //   name: "integration-firstbot",
        //   component: () =>
        //     import(/* webpackChunkName: "conversaciones" */ "./views/Integraciones/firstbot.vue"),
        // },
        {
          path: '/integration-install',
          name: 'integration-install',
          component: () =>
            import(
              /* webpackChunkName: "conversaciones" */ './views/Integraciones/installbot.vue'
            ),
        },
        {
          path: '/stripe',
          name: 'stripe',
          component: () => import('./views/Stripe.vue'),
        },
      ],
    },
    {
      path: '/',
      component: DashboardLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () =>
            import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
        },
        {
          path: '/guide/:id',
          name: 'guia',
          component: () =>
            import(/* webpackChunkName: "client_show" */ './views/Guide.vue'),
        },
        {
          path: '/clients',
          name: 'clients-show',
          component: () =>
            import(
              /* webpackChunkName: "client_show" */ './views/Clients/_id.vue'
            ),
        },
        {
          path: '/clients/detail-lead/:id',
          name: 'detail-lead',
          component: () =>
            import(
              /* webpackChunkName: "client_show" */ './views/Clients/Detail_id.vue'
            ),
        },
        {
          path: '/role/new',
          name: 'rolecreate',
          component: () =>
            import(/* webpackChunkName: "role_new" */ './views/Roles/new.vue'),
        },
        {
          path: '/pago-exitoso/:id',
          name: 'pago-exitoso',
          component: () =>
            import(/* webpackChunkName: "role_new" */ './views/SuccessPay.vue'),
        },
        {
          path: '/role/edit/:id',
          name: 'roleedit',
          component: () =>
            import(
              /* webpackChunkName: "role_edit" */ './views/Roles/edit.vue'
            ),
        },
        {
          path: '/agente/new',
          name: 'agentenew',
          component: () =>
            import(
              /* webpackChunkName: "agent_new" */ './views/Agentes/new.vue'
            ),
        },
        {
          path: '/agente/edit/:id',
          name: 'agenteedit',
          component: () =>
            import(
              /* webpackChunkName: "agent_edit" */ './views/Agentes/edit.vue'
            ),
        },
        {
          path: '/role/list',
          name: 'rolelist',
          component: () =>
            import(
              /* webpackChunkName: "role_index" */ './views/Roles/index.vue'
            ),
        },
        {
          path: '/agentes',
          name: 'agentes',
          component: () =>
            import(
              /* webpackChunkName: "agent_index" */ './views/Agentes/index.vue'
            ),
        },
        {
          path: '/agente/nuevo',
          name: 'agente',
          component: () =>
            import(
              /* webpackChunkName: "agent_newer" */ './views/Agentes/newe.vue'
            ),
        },
        {
          path: '/profile',
          name: 'profile',
          component: () =>
            import(/* webpackChunkName: "profile" */ './views/NewProfile.vue'),
        },
        {
          path: '/chatbot-center/skill-base/:id',
          name: 'Skill Base',
          component: () =>
            import(
              /* webpackChunkName: "ChatbotDashboard" */ './views/ChatbotCenter/SkillBase/index.vue'
            ),
        },
        {
          path: '/chatbot-center/terminar',
          name: 'TerminarView',
          component: () =>
            import(
              /* webpackChunkName: "ChatbotDashboard" */ './views/ChatbotCenter/SkillBase/TerminarView.vue'
            ),
        },
        {
          path: '/dashboard/chatbot/preguntas',
          name: 'faqs',
          component: () =>
            import(
              /* webpackChunkName: "ChatbotDashboard" */ './views/Chatbot/Faqs.vue'
            ),
        },
        {
          path: '/chatbot/new',
          name: 'chatbot-new',
          component: () =>
            import(
              /* webpackChunkName: "Chatbot_new" */ './views/Chatbot/new.vue'
            ),
        },
        {
          path: '/historial-conversaciones',
          name: 'conversations-show',
          component: () =>
            import(
              /* webpackChunkName: "client_show" */ './views/Conversations/_id.vue'
            ),
        },
        {
          path: '/chatbot/edit',
          name: 'chatbot-edit',
          component: () =>
            // import ( /* webpackChunkName: "Chatbot" */ './views/ChatbotEdit.vue')
            import(
              /* webpackChunkName: "Chatbot_edit" */ './views/Chatbot/edit.vue'
            ),
        },
        {
          path: '/chatbot/configuracion',
          name: 'chatbot-configuracion',
          component: () =>
            // import ( /* webpackChunkName: "Chatbot" */ './views/ChatbotEdit.vue')
            import(
              /* webpackChunkName: "Chatbot_edit" */ './views/Chatbot/Settings.vue'
            ),
        },
        {
          path: '/chatbot/configuracion/menu',
          name: 'chatbot-configuracion-menu',
          component: () =>
            // import ( /* webpackChunkName: "Chatbot" */ './views/ChatbotEdit.vue')
            import(
              /* webpackChunkName: "Chatbot_edit" */ './views/Chatbot/InitialMenu.vue'
            ),
        },
        {
          path: '/databot-live',
          name: 'databot-live',
          component: () =>
            import(
              /* webpackChunkName: "DatabotLive" */ './views/DatabotLive.vue'
            ),
        },
        {
          path: '/facturacion',
          name: 'facturacion',
          component: () =>
            import(
              /* webpackChunkName: "DatabotLive" */ './views/NewFacturacion.vue'
            ),
        },
        {
          path: '/soporte',
          name: 'soporte',
          component: () =>
            import(/* webpackChunkName: "soporte" */ './views/Soporte.vue'),
        },
        {
          path: '/notificaciones',
          name: 'Notifications',
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ './views/Notifications.vue'
            ),
        },
        // {
        //   path: "/incidencias",
        //   name: "incidencias",
        //   component: () =>
        //     import(/* webpackChunkName: "facturación_upload" */ "./views/Incidences.vue"),
        // },

        // SUPER ADMIN
        // {
        //   path: "/superadmin",
        //   name: "superadmin-index",
        //   component: () =>
        //     import(/* webpackChunkName: "super_admin" */ "./views/superadmin/index.vue")
        // },
        // {
        //   path: "/superadmin/usuarios",
        //   name: "superadmin-usuarios",
        //   component: () =>
        //     import(/* webpackChunkName: "super_admin_Users" */ "./views/superadmin/usuarios.vue")
        // },
        // {
        //   path: "/superadmin/bots",
        //   name: "superadmin-bots",
        //   component: () =>
        //     import(/* webpackChunkName: "super_admin_bots" */ "./views/superadmin/bots.vue")
        // },
        // {
        //   path: "/superadmin/chatbot",
        //   name: "superadmin-chatbot",
        //   component: () =>
        //     import(/* webpackChunkName: "super_admin_bots" */ "./views/superadmin/chatbot.vue")
        // },
        // {
        //   path: "/superadmin/indexbussi",
        //   name: "superadmin-indexbussi",
        //   component: () =>
        //     import(/* webpackChunkName: "super_admin_bots" */ "./views/superadmin/indexbussi.vue")
        // },
        // {
        //   path: "/superadmin/editbussi/:id",
        //   name: "superadmin-editbussi",
        //   component: () =>
        //     import(/* webpackChunkName: "super_admin_bots" */ "./views/superadmin/editbussi.vue")
        // },
        // {
        //   path: "/superadmin/leads",
        //   name: "superadmin-leads",
        //   component: () =>
        //     import(/* webpackChunkName: "super_admin_bots" */ "./views/superadmin/leads.vue")
        // },
        // {
        //   path: "/superadmin/facturas",
        //   name: "superadmin-facturas",
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "DatabotLive" */ './views/DatabotLive.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/newfacturas',
        //   name: 'superadmin-facturas-new',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/facturas/new.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/editfacturas/:id',
        //   name: 'superadmin-facturas-edit',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/facturas/edit.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/newagente',
        //   name: 'superadmin-newagente',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/newagente.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/editagente/:id',
        //   name: 'superadmin-editagente',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/editagente.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/newbusiness',
        //   name: 'superadmin-newbusiness',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/newbusiness.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/editbot/:id',
        //   name: 'superadmin-editbot',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/bot/edit.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/planes',
        //   name: 'superadmin-planes',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/planes/index.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/editplan/:id',
        //   name: 'superadmin-edit',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/planes/edit.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/profileplan',
        //   name: 'superadmin-profileplan',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/planes/profile/index.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/newprofile',
        //   name: 'superadmin-',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/planes/profile/new.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/editprofile/:id',
        //   name: 'superadmin-editprofile',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/planes/profile/edit.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/newplanes',
        //   name: 'superadmin-newplanes',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/planes/new.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/qvo',
        //   name: 'superadmin-qvo',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/qvoplans/index.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/edit/:id',
        //   name: 'superadmin-qvoedit',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/qvoplans/edit.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/userbot',
        //   name: 'superadmin-userbot',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/userbot/index.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/databotlive',
        //   name: 'superadmin-databotlive',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/databot_live/index.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/chatroom',
        //   name: 'superadmin-chatroom',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/chat_room/index.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/editchat/:id',
        //   name: 'superadmin-editchat',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/chat_room/edit.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/conversations',
        //   name: 'superadmin-conversations',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/conversations.vue'
        //     ),
        // },
        // {
        //   path: '/superadmin/crm',
        //   name: 'superadmin-crm',
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "super_admin_bots" */ './views/superadmin/crm.vue'
        //     ),
        // },
        {
          path: '/widgetWhatsapp',
          name: 'widgetWhatsapp',
          component: () =>
            import(
              /* webpackChunkName: "conversaciones" */ './views/widgetWhatsapp/edit.vue'
            ),
        },
        {
          path: '/dashboard/checkout',
          name: 'dashboard-checkout',
          component: () =>
            import(
              /* webpackChunkName: "conversaciones" */ './views/NewCheckout.vue'
            ),
        },
        {
          path: '/dashboard/planes',
          name: 'dashboard-planes',
          component: () =>
            import(
              /* webpackChunkName: "conversaciones" */ './views/NewPlanes.vue'
            ),
        },
        {
          path: '/whatsapp-business',
          name: 'whatsapp-business',
          component: () =>
            import(
              /* webpackChunkName: "conversaciones" */ './views/WhatsappBusiness.vue'
            ),
        },
        {
          path: '/whatsapp-estadisticas',
          name: 'mensajes-plantilla',
          component: () =>
            import(
              /* webpackChunkName: "conversaciones" */ './views/MensajesPlantilla.vue'
            ),
        },
        {
          path: '/integraciones',
          name: 'integraciones-view',
          component: () =>
            import(
              /* webpackChunkName: "conversaciones" */ './views/Integraciones/IntegracionesView.vue'
            ),
        },
        {
          path: '/automatizaciones',
          name: 'automatizaciones',
          component: () =>
            import(
              /* webpackChunkName: "automatizaciones" */ './views/Automatizaciones/index.vue'
            ),
        },
        {
          path: '/automatizaciones/carro-abandonado',
          name: 'carro-abandonado',
          component: () =>
            import(
              /* webpackChunkName: "carro-abandonado" */ './views/Automatizaciones/CarroAbandonado.vue'
            ),
        },
        {
          path: '/automatizaciones/error-pago',
          component: () =>
            import(
              /* webpackChunkName: "problema-pago" */ './views/Automatizaciones/ErrorPago.vue'
            ),
        },
        {
          path: '/preview',
          name: 'chatbot-preview',
          component: () => import('./views/PreviewBot.vue') },
        {
          path: '/automatizaciones/seguimiento-pedidos',
          component: () =>
            import(
              /* webpackChunkName: "problema-pago" */ './views/Automatizaciones/SeguimientoPedidos.vue'
            ),
        },
        {
          path: '/menu-inicial',
          name: 'menu-inicial',
          component: () =>
            import(
              /* webpackChunkName: "menu-inicial" */ './views/Chatbot/InitialMenu.vue'
            ),
        },
        // 404 - tiene que ir al final
        {
          path: '*',
          name: 'not_found',
          component: () =>
            import(/* webpackChunkName: "dashboard" */ './views/NotFound.vue'),
        },
      ],
    },
  ],
});

// redireccion de vista login a dashboard si ya estoy logueado
router.beforeEach((to, from, next) => {
  const requiresRedirect = to.matched.some(
    record => record.meta.requiresRedirect,
  );

  const isUserLogged = store.state.user.id;
  if (requiresRedirect && !isUserLogged) {
    return next({ name: 'login' });
  }
  if (requiresRedirect && isUserLogged) {
    return next({ name: 'dashboard' });
  }

  // Redireccion de vista registro a dashboard si ya estoy logueado y activar plan
  const redirectRegister = to.matched.some(
    record => record.meta.redirectRegister,
  );

  if (redirectRegister && !isUserLogged) {
    return next();
  }
  if (redirectRegister && isUserLogged && Object.keys(to.query).length == 0) {
    return next({ name: 'dashboard' });
  }

  // Redirección vistas dashboard si no estoy logueado
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !isUserLogged) {
    return next({ name: 'login' });
  }
  if (requiresAuth && isUserLogged) {
    return next();
  }

  return next();
});

export default router;
