const { origin } = window.location;

let url_env = '';
let socket_url = '';
let url_env_bot = '';
let url_env_bot_front = '';
let url_env_self = '';
let RASA_URL = '';
let DATABOT_TEMPLATES = '';
let url_massive_tags = '';

if (origin == 'https://dashboard-front-qa.herokuapp.com') {
  url_env = 'https://qapi.databot.cl';
  // url_env = "https://databot-dashboard-api-qa.herokuapp.com";
  url_env_bot = 'https://chatbot-front-qa.herokuapp.com';
  socket_url = 'https://chatbot-front-qa.herokuapp.com';
  url_env_bot_front = 'http://bot-front-qa.s3-website-us-east-1.amazonaws.com';
  url_env_self = 'https://dashboard-front-qa.herokuapp.com';
  RASA_URL = 'https://rasaia2.databot.cl';
  DATABOT_TEMPLATES = 'https://databot-templates-144c5a974b97.herokuapp.com';
  url_massive_tags = '';
} else if (process.env.NODE_ENV === 'development') {
  url_env = 'http://localhost:3000';
  socket_url = 'http://localhost:5000';
  url_env_bot = 'http://localhost:5000';
  url_env_bot_front = 'http://localhost:8000';
  url_env_self = 'http://localhost:7000';
  RASA_URL = 'http://localhost:9090';
  DATABOT_TEMPLATES = 'https://databot-templates-144c5a974b97.herokuapp.com';
  url_massive_tags = 'http://localhost:3200';
} else {
  url_env = 'https://api.databot.cl';
  socket_url = 'https://databot-chatbot-backend.herokuapp.com';
  url_env_bot = 'https://databot-chatbot-backend.herokuapp.com';
  url_env_bot_front = 'https://databot-api.herokuapp.com';
  url_env_self = 'https://ia.databot.cl';
  RASA_URL = 'https://rasaia2.databot.cl';
  DATABOT_TEMPLATES = 'https://databot-templates-144c5a974b97.herokuapp.com';
  url_massive_tags = 'http://api-wsp-prod.southcentralus.cloudapp.azure.com';
}

export default {
  url_env,
  socket_url,
  url_env_bot,
  url_env_self,
  RASA_URL,
  url_env_bot_front,
  DATABOT_TEMPLATES,
  url_massive_tags
};
